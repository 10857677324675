import React, { useState, useEffect } from 'react';
import {
  Navbar,
  Nav,
  Button,
  Offcanvas,
  Form,
  FormControl,
  Modal
} from 'react-bootstrap';
import {
  FaUserFriends,
  FaShoppingCart,
  FaBars,
  FaInstagram,
  FaEnvelope,
  FaTiktok,
  FaWhatsapp,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import logo from './img/logo.png';
import './Navbar.css';
import Footer from './Footer';

const MyNavbar = ({ config }) => {
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showFriends, setShowFriends] = useState(false); // Estado para el Offcanvas de Mejores Amigos
  const [email, setEmail] = useState(''); // Estado para almacenar el correo electrónico
  const [error, setError] = useState(''); // Estado para manejar errores
  const navigate = useNavigate();
  const apiUrl = config.apiUrl;

  // Fetch para obtener productos y extraer categorías únicas
  useEffect(() => {
    fetch(`${apiUrl}/api/products`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const uniqueCategories = [...new Set(data.map(product => product.category))];
        setCategories(uniqueCategories);
      })
      .catch(error => console.error('Error fetching products:', error));
  }, [apiUrl]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
    handleClose();
  };

  const handleFriendsClose = () => setShowFriends(false);
  const handleFriendsShow = () => setShowFriends(true); // Abre el Offcanvas de Mejores Amigos

  // Función para manejar el submit del formulario
  // Función para manejar el submit del formulario
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Limpiar errores previos
    console.log(`${config.apiUrl}/api/best-friends/login?email=${email}`);
    try {
      const response = await fetch(`${config.apiUrl}/api/best-friends/login?email=${email}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('No eres de nuestro club :(\nCompra un producto y accede');
      }

      // Usamos response.text() en lugar de response.json() porque el servidor devuelve un string
      const token = await response.text();

      // Almacena el token en localStorage
      localStorage.setItem('token', token);

      // Redirigir si obtenemos un token
      if (token) {
        setShowFriends(false);
        navigate('/best');
      }
    } catch (err) {
      setError(err.message); // Muestra el error en caso de fallo
    }
  };



  return (
    <>
      <Navbar expand="lg" className="d-flex justify-content-between">
        <div className="d-lg-none ms-2">
          <Button onClick={handleShow} className="button">
            <FaBars />
          </Button>
        </div>

        <Navbar.Brand href="/" className="mx-auto mx-lg-0">
          <img src={logo} alt="Logo" height="40" className="p-1 ps-4" />
        </Navbar.Brand>

        <div className="d-lg-none d-flex align-items-center pe-2">
          <Button onClick={handleFriendsShow} className="button">
            <FaUserFriends className="mx-2" onClick={handleFriendsShow} />
          </Button>
          <Nav.Link href="/cart">
            <FaShoppingCart className="mx-2 fa" />
          </Nav.Link>
        </div>

        <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex justify-content-center ps-2">
          <Nav className="mr-auto">
            <Nav.Link href="/">HOME</Nav.Link>
            {categories.map((category, index) => (
              <Nav.Link key={`${category}-${index}`} onClick={() => handleCategoryClick(category)}>
                {category}
              </Nav.Link>
            ))}
            <Nav.Link href="/about" style={{ marginLeft: '30px' }}>
              ABOUT US
            </Nav.Link>
            <Nav.Link href="/restock" style={{ marginTop: '0px' }}>
              DEVOLUCIONES
            </Nav.Link>
            <Nav.Link href="/locker" style={{ marginTop: '0px' }}>
              LOCKER
            </Nav.Link>
            <Nav.Link href="/collections" style={{ marginTop: '0px' }}>
              COLLECTIONS
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        

        <div className="d-none d-lg-flex align-items-center pe-2">
          <Button onClick={handleFriendsShow} className="button">
            <FaUserFriends className="mx-2 fa" />
          </Button>
          <Nav.Link href="/cart">
            <FaShoppingCart className="mx-2 fa" />
          </Nav.Link>
        </div>
      </Navbar>


      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">

            <Nav.Link href="/locker" style={{ marginTop: '0px' }}>
              LOCKER
            </Nav.Link>
            <Nav.Link href="/collections" style={{ marginTop: '0px' }}>
              COLLECTIONS
            </Nav.Link>

            <div style={{ borderBottom: '1px solid #000', margin: '10px 0' }}></div>

            {categories.map((category, index) => (
              <Nav.Link key={`${category}-${index}`} onClick={() => handleCategoryClick(category)}>
                {category}
              </Nav.Link>
            ))}

            <div style={{ borderBottom: '1px solid #000', margin: '10px 0' }}></div>

            <Nav.Link href="/about" style={{ marginTop: '10px' }}>
              ABOUT US
            </Nav.Link>
            <Nav.Link href="/restock" style={{ marginTop: '0px' }}>
              DEVOLUCIONES
            </Nav.Link>

          </Nav>
          <div className="offcanvas-social mt-auto">
            <div className="d-flex justify-content-center">
              <a href={`mailto:${config.correo}`} className="social-icon">
                <FaEnvelope />
              </a>
              {config.tiktok ? <a href={`https://www.tiktok.com/@${config.tiktok}?_t=8pxVIfRQge8&_r=1`} className="social-icon"><FaTiktok /></a> : ''}
              {config.instagram ? <a href={`https://www.instagram.com/${config.instagram}`} className="social-icon"><FaInstagram /></a> : ''}
              {config.whatsapp ? <a href={`https://wa.me/34${config.whatsapp}`} className="social-icon"><FaWhatsapp /></a> : ''}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Offcanvas para Mejores Amigos */}
      <Offcanvas show={showFriends} onHide={handleFriendsClose} placement="end" className="offcanvas-friends">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>FRIENDS & FAMILY</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>Bienvenido a la sección de FRIENDS & FAMILY. Por favor, verifica tu correo electrónico para continuar.</p>
          <Form onSubmit={handleEmailSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Correo Electrónico</Form.Label>
              <FormControl
                type="email"
                placeholder="Ingresa tu correo"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            {error && (
              <div className="error-message">
                {error.split('\n').map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            )}

            <Button type="submit" variant="primary" className="mt-3">
              Verificar
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Footer config={config} />
    </>
  );
};

export default MyNavbar;
