import React from 'react';
import Slider from 'react-slick';
import './Category.css'; // Asegúrate de tener el CSS adecuado
//import { config } from '../config';

const Categories = ({ categories, products, config }) => {
  // Configuración del carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Número de tarjetas que se mostrarán en cada slide
    slidesToScroll: 1,
  };

  return (
    <div className="categories-container">
      <Slider {...settings}>
        {categories.length > 0 ? (
          categories.map((category, index) => {
            // Encuentra la primera imagen del primer producto de esta categoría
            const categoryProducts = products.filter(product => product.category === category);
            const firstImage = categoryProducts.length > 0 ? categoryProducts[0].images[0] : '';

            return (
              <div key={index} className="category-card" onClick={() => window.location.href = `/category/${category}`}>
                <div className="category-background" style={{ backgroundImage: `url(${config.apiUrl}/img/${category}.png)` }}>
                  <div className="category-info">
                    <h3 className="category-title">{category}</h3>
                    <p className="category-count">{categoryProducts.length} products</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>No hay categorías disponibles</div>
        )}
      </Slider>
    </div>
  );
};

export default Categories;
